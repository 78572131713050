import React, {useRef} from "react";
import PropTypes from "prop-types";
import ProcessBlock from "../../components/ProcessBlock";
import Typist from "react-typist";
import {motion} from "framer-motion";
import useOnScreen from "../../hooks/useOnScreen";
import classNames from "classnames";
import {typistServiceTitleAnimation} from "../../settings/generalAnimations";
import {GatsbyImage as Image} from "gatsby-plugin-image";
import {Link} from "gatsby";
import createValidLink from "../../helpers/urlFixer";

const ServicesMarketing = ({title, description, image, stepsTitle, stepsBlocks, linkText, linkUrl}) => {
    const reversedStepBlocks = [...stepsBlocks];
    const imageRef = useRef();
    const infoRef = useRef();
    const isInfoVisible = useOnScreen(infoRef);
    const isImageVisible = useOnScreen(imageRef, {threshold: 0.7});

    const wrapper = useRef();
    const isActive = useOnScreen(wrapper, {threshold: 0.7, once: true});

    const imageClasses = classNames({
        "services-box-content__media": true,
        "is-visible": isImageVisible
    });

    return (
        <section id="marketing" className="services-marketing">
            <div className="container container--medium">
                <div className="services-box-content">
                    <div className="services-box-content__info" ref={infoRef}>
                        {title && (
                            <h2 className="services-content-image__title section-title section-title--black">
                                {isInfoVisible ? (
                                    <Typist {...typistServiceTitleAnimation}>
                                        <Typist.Delay ms={500}/>

                                        {title}
                                    </Typist>
                                ) : title}
                            </h2>
                        )}

                        {description && (
                            <motion.div
                                className="services-content-image__text"
                                initial="hidden"
                                whileInView="visible"
                                viewport={{once: true}}
                                transition={{duration: 0.8, delay: 0.6}}
                                variants={{
                                    visible: {opacity: 1, top: 0},
                                    hidden: {opacity: 0, top: 80}
                                }}
                                dangerouslySetInnerHTML={{__html: description}}
                            />
                        )}
                    </div>

                    <div className={imageClasses} ref={imageRef}>
                        <Image
                            image={image.localFile?.childImageSharp.gatsbyImageData}
                            alt={image.altText || "Services"}
                            title={image.altText || "Services"}
                        />
                    </div>
                </div>

                {reversedStepBlocks.length && (
                    <div className="round-steps services-marketing__steps" ref={wrapper}>
                        {stepsTitle && (
                            <h3 className="services-medium-title medium-title">{stepsTitle}</h3>
                        )}

                        <div className="round-steps__blocks">
                            <div className="round-steps__blocks-wrapper" aria-hidden={true}>
                                {reversedStepBlocks.map((step) => (
                                    <ProcessBlock
                                        active={isActive}
                                        key={step.number}
                                        {...step}
                                        extraClass="process-block--6"
                                    />
                                ))}
                            </div>

                            <div className="round-steps__blocks-wrapper visually-hidden">
                                {stepsBlocks.map((step) => (
                                    <ProcessBlock
                                        active={isActive}
                                        key={step.number}
                                        {...step}
                                        extraClass="process-block--6"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {linkText && linkUrl && (
                    <div className="services-marketing__link-wrapper">
                        <Link to={createValidLink(linkUrl)} className="button button--large">{linkText}</Link>
                    </div>
                )}
            </div>
        </section>
    );
};

ServicesMarketing.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    stepsTitle: PropTypes.string,
    stepsBlocks: PropTypes.array,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string
}

export default ServicesMarketing;
