import React from "react"
import PropTypes from "prop-types";
import classNames from "classnames"
import { GatsbyImage as Image } from "gatsby-plugin-image";

const ServiceCard = ({title, description, imageUrl, isWhite}) => {
	const serviceCardClasses = classNames({
		"service-card": true,
		"service-card--white": isWhite
	});
	return (
		<div className={serviceCardClasses}>
			<div className="service-card__image-wrapper">
					<Image
						className="service-card__image"
						image={imageUrl.localFile?.childImageSharp?.gatsbyImageData}
						alt={imageUrl.altText || 'service-card-image'}
						title={imageUrl.altText || 'service-card-image'}
					/>
			</div>

			<div className="service-card__content">
				<h3 className="service-card__title">
					{title}
				</h3>

				<div
					className="service-card__rte"
					dangerouslySetInnerHTML={{
						__html: description
					}}
				/>
			</div>
		</div>
	);
}

ServiceCard.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	imageUrl: PropTypes.object,
	isWhite: PropTypes.bool
}

export default ServiceCard;

