import React, {useRef} from "react";
import PropTypes from "prop-types";
import ProcessBlock from "../../components/ProcessBlock";
import useOnScreen from "../../hooks/useOnScreen";
import createValidLink from "../../helpers/urlFixer";
import {Link} from "gatsby";

const ServicesResearch = ({ title, description, stepsTitle, stepsBlocks, linkText, linkUrl}) => {
	const reversedStepBlocks = [...stepsBlocks];

	const wrapper = useRef();
	const isActive = useOnScreen(wrapper, { threshold: 0.7, once: true });

	return (
		<section id="research-process" className="services-research">
			<div className="container container--medium">
				<div className="services-middle-content services-middle-content--gray">
					<div className="services-middle-content__wrapper">
						{title && (
							<h2 className="section-title section-title--black">Research process</h2>
						)}

						{description && (
							<div className="services-middle-content__text" dangerouslySetInnerHTML={{__html: description}}></div>
						)}
					</div>
				</div>

				{reversedStepBlocks.length && (
					<div className="round-steps services-research__steps" ref={wrapper}>
						{stepsTitle && (
							<h3 className="services-medium-title medium-title">{stepsTitle}</h3>

						)}

						<div className="round-steps__blocks">
							<div className="round-steps__blocks-wrapper" aria-hidden={true}>
								{reversedStepBlocks.map((step) => (
									<ProcessBlock
										active={isActive}
										key={step.number}
										{...step}
										extraClass="process-block--6"
									/>
								))}
							</div>

							<div className="round-steps__blocks-wrapper visually-hidden">
								{stepsBlocks.map((step) => (
									<ProcessBlock
										active={isActive}
										key={step.number}
										{...step}
										extraClass="process-block--6"
									/>
								))}
							</div>
						</div>
					</div>
				)}

				{linkText && linkUrl && (
					<div className="services-research__link-wrapper">
						<Link to={createValidLink(linkUrl)} className="button button--large">{linkText}</Link>
					</div>
				)}
			</div>
		</section>
	);
}

ServicesResearch.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	stepsTitle: PropTypes.string,
	stepsBlocks: PropTypes.array,
	linkText: PropTypes.string,
	linkUrl: PropTypes.string
}

export default ServicesResearch;
