import React, {useRef} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import handleSVG from "../helpers/handleSVG";

const ProcessBlock = ({ title, number, icon, extraClass,active }) => {
	const blockRef = useRef();
	const BlockClasses = classNames({
		"process-block": true,
		[extraClass]: !!extraClass,
		"is-active": active
	});

	return (
		<div className={BlockClasses} ref={blockRef}>
			<div className="process-block__number-wrapper" aria-hidden="true">
				<div className="process-block__number">
					{number}
				</div>

				<div
					className="process-block__icon"
					dangerouslySetInnerHTML={{
						__html: handleSVG(icon)
					}}
				/>
			</div>

			<h4 className="process-block__title" dangerouslySetInnerHTML={{ __html: title }} />
		</div>
	);
};

ProcessBlock.propTypes = {
	title: PropTypes.string.isRequired,
	number: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	extraClass: PropTypes.string
}

export default ProcessBlock;
