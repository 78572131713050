import React from "react"
import PropTypes from "prop-types";
import ProjectItem from "../components/ProjectItem";
import createValidLink from "../helpers/urlFixer";
import {Link} from "gatsby";

const OurPortfolio = ({ title, projects, linkText, linkUrl }) => {
	if(!projects || !projects?.length) {
		return null;
	}

	return (
		<section className="our-portfolio-general">
			{title && (
				<div className="container container--medium container--mobile-small">
					<h2 className="our-portfolio-general__title section-title">{title}</h2>
				</div>
			)}

			<div className="container container--mobile-small">
				<div className="our-portfolio-general__blocks">
					<div className="our-portfolio-general__blocks-wrapper">
						{projects.map(project => (
							<ProjectItem
								key={project.id}
								title={project.title}
								slug={project.slug}
								technologies={project.technologies}
								squaredImage={project.acfProject?.squaredImage}
							/>
						))}
					</div>
				</div>

				{linkText && linkUrl && (
					<div className="our-portfolio-general__link-wrapper">
						<Link to={createValidLink(linkUrl)}
						   className="button button--transparent button--large"
						>
							{linkText}
						</Link>
					</div>
				)}
			</div>
		</section>
	);
}

OurPortfolio.propTypes = {
	title: PropTypes.string,
	projects: PropTypes.array.isRequired,
	linkText: PropTypes.string,
	linkUrl: PropTypes.string
}

export default OurPortfolio;
