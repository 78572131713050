import {useLayoutEffect, useState} from "react";

const useOnScreen = (ref, options) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useLayoutEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            options?.once ? (entry.isIntersecting && setIsIntersecting(true)) : setIsIntersecting(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.unobserve(ref.current);
        };
    }, []);

    return isIntersecting;
};

export default useOnScreen;
