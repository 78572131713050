export const bannerSubtitleAnimation = {
	transition: {
		duration: 0.8,
		delay: 0.6
	},
	initial: {
		top: 80,
		opacity: 0
	},
	animate: {
		top: 0,
		opacity: 1
	}
};

export const typistServiceTitleAnimation = {
	cursor: {
		hideWhenDone: true,
		blink: true
	}
}
