import React, {useEffect, useRef, useState} from "react";
import { graphql, useStaticQuery } from "gatsby";
import useSize from "@react-hook/size";

const selectors = {
	navigationBar: ".js-navigation-bar",
	navigationBarAnchorsList: ".js-navigation-bar-anchors-list",
	navigationBarButton: ".js-navigation-bar-button",
	navigationBarAnchorLinks: ".js-navigation-bar-anchor"
};

const modifiers = {
	active: "active",
	navigationBarOpen: "open"
};

const NavigationBar = ({ children }) => {
	const isSSR = typeof window === "undefined";

	const navigationRef = useRef();

	const [navigationStyle, setNavigationStyle] = useState("");

	const navigationTogglerRef = useRef();

	const { wpMenu: menu } = useStaticQuery(graphql`
		query NavigationBar {
			wpMenu(locations: {eq: SERVICES}) {
				id
				menuItems {
					nodes {
						url
						label
						id
					}
				}
			}
		}
	`);

	const navBarMenuItems = menu?.menuItems?.nodes;

	const [isNavbarHidden, setIsNavbarHidden] = useState(false);

	const [, height] = useSize(navigationRef);

	useEffect(() => {
		const styleElement = document.createElement('style');

		styleElement.id = 'navigationBar';

		document.head.appendChild(styleElement);

		return () => {
			document.head.removeChild(styleElement);
		};
	}, []);

	useEffect(() => {
		const styleElement = document.getElementById('navigationBar');

		if (styleElement) {
			styleElement.textContent = navigationStyle;
		}
	}, [navigationStyle]);

	useEffect(() => {
		setNavigationStyle(`
		:root {
			--additional-sticky-part: ${height}px;
		}`)
	},[height]);

	useEffect(() => {
		if (isSSR) {
			return null;
		}

		const handleScroll = () => {
			const navigationBar = document.querySelector(selectors.navigationBar);

			if (!navigationBar) {
				return;
			}

			const navigationAnchorLinks = [...navigationBar.querySelectorAll(selectors.navigationBarAnchorLinks)];
			const navigationBarButton 	= navigationBar.querySelector(selectors.navigationBarButton);

			const navigationBarWrapperHeight 	= navigationBar.offsetHeight;

			document.dispatchEvent(new CustomEvent("header:toggle", {
				detail: {
					value: false,
				}
			}));

			navigationAnchorLinks.forEach(link => {
				const anchorSection = document.getElementById(link.hash.slice(1));

				if (!anchorSection) {
					return;
				}

				const headerHeight = getComputedStyle(document.documentElement).getPropertyValue('--header-height');

				const isWindowScrollYUnderAnchorSection = window.scrollY >= anchorSection.offsetTop - navigationBarWrapperHeight - parseInt(headerHeight);

				if (isWindowScrollYUnderAnchorSection) {
					navigationBarButton.textContent = link.textContent;

					navigationAnchorLinks.forEach(navigationAnchorLink => {
						navigationAnchorLink.classList.remove(modifiers.active);
					});
				}

				link.classList.toggle(modifiers.active, isWindowScrollYUnderAnchorSection);
			});

			const navigationAnchorLinkLast = navigationAnchorLinks.at(-1);
			const anchorSection = document.getElementById(navigationAnchorLinkLast.hash.slice(1));

			const isNavbarHidden = window.scrollY > (anchorSection.offsetTop  + anchorSection.offsetHeight);

			setIsNavbarHidden(isNavbarHidden);
		};

		handleScroll();

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [isSSR]);

	const toggleNavigationList = () => {
		const navigationBar = document.querySelector(selectors.navigationBarAnchorsList);

		if (!navigationBar) return;

		navigationTogglerRef.current.classList.toggle(modifiers.active);

		navigationBar.style.maxHeight = navigationBar.scrollHeight + "px";

		if (navigationBar.dataset.open === "false") {
			setTimeout(() => {
				navigationBar.style.maxHeight = "unset";
				navigationBar.style.visibility = "visible";
				navigationBar.style.overflow = "visible";
			}, 300);

			navigationBar.dataset.open = "true";
			return;
		}

		setTimeout(() => navigationBar.style.maxHeight = "");
		navigationBar.style.overflow = "hidden"
		navigationBar.dataset.open = "false";
	};

	return (
		<>
			{Boolean(navBarMenuItems.length) && (
				<div className={`navigation-bar js-navigation-bar ${isNavbarHidden ? "hide" : ""}`} ref={navigationRef}>
					<button
						ref={navigationTogglerRef}
						className="navigation-bar__button js-navigation-bar-button"
						onClick={toggleNavigationList}
						type="button"
						aria-label="Open page navigation"
					>
						Navigation
					</button>

					<ul className="navigation-bar__list js-navigation-bar-anchors-list" data-open="false">
						{navBarMenuItems.map(({ label, url, id }) =>
							<li
								className="navigation-bar__list-item"
								key={id}
							>
								<a
									href={url}
								    className="navigation-bar__link js-navigation-bar-anchor"
								    key={id}
								>
									{label}
								</a>
							</li>
						)}
					</ul>
				</div>
			)}

			{children}
		</>
	);
};

export default NavigationBar;
