import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import DefaultHelmet from "../seo/DefaultHelmet";
import VideoSimpleBanner from "../components/VideoSimpleBanner";
import ServicesUi from "../sections/Services/ServicesUi";
import ServicesDevelopment from "../sections/Services/ServicesDevelopment";
import ServicesSeo from "../sections/Services/ServicesSeo";
import ServicesMarketing from "../sections/Services/ServicesMarketing";
import ServicesQuality from "../sections/Services/ServicesQuality";
import ServicesResearch from "../sections/Services/ServicesResearch";
import OurPortfolio from "../sections/OurPortfolio";
import NavigationBar from "../components/NavigationBar";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";
import {graphql, useStaticQuery} from "gatsby";

const ServicesPage = ({ pageContext }) => {
	const {
		title,
		acfServicesPage,
		expertiseItems,
		pageHeader,
	} = pageContext;

	const getSection = ({ fieldGroupName, ...section }, index) => {
		const key = index;

		switch (fieldGroupName) {
			case 'page_Acfservicespage_Sections_UxUi': {
				return (
					<ServicesUi
						key={key}
						{...section}
					/>
				);
			}

			case 'page_Acfservicespage_Sections_Development': {
				return (
					<ServicesDevelopment
						key={key}
						expertiseItems={expertiseItems}
						{...section}
					/>
				)
			}

			case 'page_Acfservicespage_Sections_Seo': {
				return (
					<ServicesSeo
						key={key}
						{...section}
					/>
				)
			}

			case 'page_Acfservicespage_Sections_Marketing': {
				return (
					<ServicesMarketing
						key={key}
						{...section}
					/>
				)
			}

			case 'page_Acfservicespage_Sections_QualityProcess': {
				return (
					<ServicesQuality
						key={key}
						{...section}
					/>
				)
			}

			case 'page_Acfservicespage_Sections_ResearchProcess': {
				return (
					<ServicesResearch
						key={key}
						{...section}
					/>
				)
			}

			case 'page_Acfservicespage_Sections_OurPortfolio': {
				return (
					<OurPortfolio
						key={key}
						{...section}
					/>
				)
			}

			default: {
				return null;
			}
		}
	};

	const getSections = () => {
		if(!Array.isArray(pageContext.acfServicesPage?.sections)) {
			return null;
		}

		return pageContext.acfServicesPage.sections.map(getSection);
	};

	const bannerTitle = acfServicesPage.bannerTitle || title || acfServicesPage?.metaData?.title || "Services";
	const pageTitle = acfServicesPage?.metaData?.title || "Services";
	const pageDescription = acfServicesPage?.metaData?.description || "Services description";

	useBeaconWidget(beaconWidgetIds.GENERAL);

	const {site} = useStaticQuery(
		graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                        title
                        author
                    }
                }
            }
        `
	);

	const microdata = {
		"@context": "https://schema.org",
		"@type": "VideoObject",
		"name": "UTD services and technologies",
		"description": "The video demonstrates the technologies and services we work with",
		"thumbnailUrl": [
			acfServicesPage.bannerVideoPoster.localFile.childImageSharp.gatsbyImageData.images.fallback.src,
		],
		"uploadDate": "2023-06-27T08:00:00+08:00",
		"duration": "PT1M2S",
		"contentUrl": site?.siteMetadata?.siteUrl + acfServicesPage.bannerVideo.mediaItemUrl,
		"interactionStatistic": {
			"@type": "InteractionCounter",
			"interactionType": { "@type": "WatchAction" },
			"userInteractionCount": 1317
		},
	};

	return (
		<DefaultLayout isHeaderWithBlackText={false}>
			<DefaultHelmet title={pageTitle} description={pageDescription} microdata={microdata}/>

			<VideoSimpleBanner
				title={bannerTitle}
				poster={acfServicesPage.bannerVideoPoster}
				showTitle={acfServicesPage.bannerShowTitle}
				html5VideoUrl={acfServicesPage.bannerVideo.mediaItemUrl}
				html5VideoType={acfServicesPage.bannerVideo.mimeType}
				pageHeader={pageHeader}
			/>

			<NavigationBar>
				{getSections()}
			</NavigationBar>
		</DefaultLayout>
	);
};

export default ServicesPage;
